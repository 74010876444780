var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "custom-class": "touristTips",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        "close-on-click-modal": false,
        top: "0",
        width: "420px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "closeBox" }, [
        _c("i", {
          staticClass: "icon-x iconfont_Me iconStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.closeDialog.apply(null, arguments)
            }
          }
        })
      ]),
      _c("div", { staticClass: "dialogHeaderContainer" }, [
        _vm._v(_vm._s(_vm.$t("notice")))
      ]),
      _c("div", { staticClass: "dialogContainer" }, [
        _c("div", { staticClass: "iconBox" }, [
          _c("i", { staticClass: "icon-remind iconfont_Me iconStyle" })
        ]),
        _c("div", { staticClass: "contentBox" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("touristAccountPleaseRegisterFirst")) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "dialogFooter" }, [
        _c(
          "div",
          {
            directives: [{ name: "waves", rawName: "v-waves" }],
            staticClass: "commonButton active",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.signup.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("signup")) + "\n    ")]
        ),
        _c(
          "div",
          {
            directives: [{ name: "waves", rawName: "v-waves" }],
            staticClass: "commonButton default",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.closeDialog.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }