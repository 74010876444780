<template>
  <el-dialog
    center
    custom-class="touristTips"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :top="'0'"
    :width="'420px'"
  >
    <div class="closeBox">
      <i class="icon-x iconfont_Me iconStyle" @click.stop="closeDialog"></i>
    </div>
    <div class="dialogHeaderContainer">{{ $t("notice") }}</div>
    <div class="dialogContainer">
      <div class="iconBox">
        <i class="icon-remind iconfont_Me iconStyle"></i>
      </div>
      <div class="contentBox">
        {{ $t("touristAccountPleaseRegisterFirst") }}
      </div>
    </div>
    <div class="dialogFooter">
      <div class="commonButton active" v-waves @click.stop="signup">
        {{ $t("signup") }}
      </div>
      <div class="commonButton default" v-waves @click.stop="closeDialog">
        {{ $t("cancel") }}
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { close as serverClose } from "@/components/business/chat/server";
import mixins from "../../mixins/dialog";
export default {
  name: "TouristTips",
  mixins: [mixins],
  computed: {
    dialogVisible() {
      return this.$store.state.globalShare.touristTips;
    },
    touristTipsFrom() {
      return this.$store.state.globalShare.touristTipsFrom;
    },
  },
  methods: {
    async signup() {
      if (this.$cookies.get("oauth_user_id")) {
        this.$cookies.remove("oauth_user_id");
      }
      await this.$http({
        url: "/helome/logout.do",
        method: "get",
      }).catch((e) => {});
      this.$store.commit("setUserinfo", {});
      serverClose();
      this.$nextTick(() => {
        if (this.touristTipsFrom == "default") {
          if (localStorage.getItem("joinMembership")) {
            this.$store.commit("setVipModeStatus", false);
          }
          setTimeout(() => {
            this.$store.commit("setLoginAndRegisterDialog", true);
            localStorage.setItem("joinMembership", true);
          }, 0);
        } else if (this.touristTipsFrom == "email") {
          this.$store.commit("setTouristTipsFrom", "default");
          this.$store.commit("setCurrent_popup_display_status", "register");
          setTimeout(() => {
            this.$store.commit("setEmail_loginAndRegisterDialog", true);
          }, 0);
        }
        this.closeDialog();
      });
    },
    closeDialog() {
      this.$store.commit("setTouristTips", false);
    },
  },
};
</script>
<style lang="stylus" scoped>
.touristTips
  .closeBox
    width: 100%;
    height: 24px;
    margin-top: 12px;
    text-align: right;
    .iconStyle
      font-size: 24px;
      color: #CFCFCF;
      font-weight: 500;
      margin-right: 13px;
      cursor pointer;
  .dialogHeaderContainer
    width: 100%;
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding:8px 25px 30px 25px;
  .dialogContainer
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 45px  20px 45px;
    .iconBox
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        font-size: 18px;
        color #FF8A00;
        font-weight: bold;
    .contentBox
      flex: 1;
      min-width: 0;
      overflow: hidden;
      color: #000000;
      font-size: 16px;
      line-height: 24px;
      word-break: normal;
  .dialogFooter
    width: 240px;
    margin: auto;
    overflow: hidden;
    padding-bottom: 50px;
    .commonButton
      width: 100%;
      height: 34px;
      border-radius: 5px;
      margin-top: 10px;
      text-align: center;
      line-height: 34px;
      font-size: 15px;
      cursor pointer;
      font-weight: 700;
      user-select none;
      .iconStyle
        font-size: 20px;
        color:#FFFFFF
    .active
      color: #FFFFFF;
      background: #33CC66;
    .default
      color: #666666;
      background: #D9D9D9;
</style>
<style lang="stylus">
.touristTips
  position: relative;
  border-radius: 15px;
  overflow hidden;
  transition: width 0.3s, height 0.3s;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding 0;
</style>
